exports.components = {
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-thinking-jsx": () => import("./../../../src/pages/thinking.jsx" /* webpackChunkName: "component---src-pages-thinking-jsx" */),
  "component---src-templates-client-page-template-jsx": () => import("./../../../src/templates/client-page-template.jsx" /* webpackChunkName: "component---src-templates-client-page-template-jsx" */),
  "component---src-templates-dex-page-template-jsx": () => import("./../../../src/templates/dex-page-template.jsx" /* webpackChunkName: "component---src-templates-dex-page-template-jsx" */),
  "component---src-templates-generic-page-template-jsx": () => import("./../../../src/templates/generic-page-template.jsx" /* webpackChunkName: "component---src-templates-generic-page-template-jsx" */),
  "component---src-templates-project-page-template-jsx": () => import("./../../../src/templates/project-page-template.jsx" /* webpackChunkName: "component---src-templates-project-page-template-jsx" */),
  "component---src-templates-thinking-article-page-template-jsx": () => import("./../../../src/templates/thinking-article-page-template.jsx" /* webpackChunkName: "component---src-templates-thinking-article-page-template-jsx" */)
}

